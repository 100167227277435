export default class TreasureDataLogger {
  constructor(eventTableName) {
    if (window.td) {
      window.td.setSignedMode();
    } else {
      // eslint-disable-next-line no-console
      console.error('TreasureDataLogger was instantiated before td was created');
    }
    this.eventTableName = eventTableName;
  }

  sendEvent(event, value, placeId, mapId, pointId, itemId) {
    let data = { event: event, value: value, place_id: placeId, map_id: mapId, point_id: pointId, item_id: itemId }
    if (typeof value === 'object') {
      data.value = null
      data = Object.assign(data, value)
    }
    window.td.trackEvent(this.eventTableName, data)
  }

  sendWatchVideoEvent(
    placeId,
    mapId, 
    pointId, 
    itemId,
    videoDuration,
    videoStart,
    videoEnd,
    signed_url_slug,
    isDownloaded,
    accountId
  ) {
    const data = { 
      event: 'watchVideo', 
      place_id: placeId, 
      map_id: mapId,
      point_id: pointId,
      item_id: itemId,
      video_duration: videoDuration,
      video_start: videoStart,
      video_end: videoEnd,
      signed_url_slug: signed_url_slug,
      is_downloaded: isDownloaded,
      account_id: accountId
    }

    window.td.trackEvent(this.eventTableName, data)
  }
}
